import { $win } from '../utils/globals';
import Swiper from 'swiper/swiper-bundle';

$('.js-slider-fullheight-images .swiper').each((i, slider) => {
	const $slider = $(slider).closest('.js-slider-fullheight-images');

	if ($slider.find('.slider__slide').length > 1) {
		$slider.addClass('is-visible');

		const swiper = new Swiper(slider, {
			slidesPerView: 1,
			loop: true,
			speed: 1000,
			slidesPerView: 1,
			slidesPerGroup: 1,
			watchOverflow: true,
			effect: 'fade',
			autoplay: true,
			fadeEffect: {
				crossFade: true
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			}
		});
	}
});
