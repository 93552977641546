const $btn = $('.js-btn-get-quote');
const $btnClose = $('.js-btn-close-form');

$btn.on('click', function (e) {
	e.preventDefault();
	$(this).next().slideDown();
});

$btnClose.on('click', function (e) {
	e.preventDefault();
	$(this).parent().slideUp();
});
