import { $win } from '../utils/globals';

const initializeMap = ($mapContainer = $('.map')) => {
	const lat = $mapContainer.data('lat');
	const lng = $mapContainer.data('lng');
	const pin = $mapContainer.data('pin');
	let map;

	   //Set the options
	const mapOptions = {
		zoom: 17,
		center: new google.maps.LatLng(lat, lng),
		styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
	};

	map = new google.maps.Map($mapContainer[0], mapOptions);

	google.maps.event.addDomListener(window, 'load', mapOptions);

	let width = 43;
	let height = 58;

	if ($win.width() < 767) {
		width = 21;
		height = 27;
	}

	console.log(width)

	const icon = {
		url: pin, // url
		scaledSize: new google.maps.Size(width, height), // scaled size
		origin: new google.maps.Point(0,0), // origin
		anchor: new google.maps.Point(0, 0) // anchor
	};

	const marker = new google.maps.Marker({
		position: mapOptions.center,
		map,
		icon: icon,
	});
}

const initMaps = ($map = $('.map')) => {
	if ( $map.length ) {
		initializeMap($map);
	}
};

initMaps()
