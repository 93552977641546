

import { $win, $body } from '../utils/globals'

let lastScroll = null;

$win.on('load scroll resize', () => {
	const topPosition = $win.scrollTop();

	$('.header').toggleClass('has-bg', topPosition > 100);

	$('.header').toggleClass('is-down', topPosition > lastScroll)

	lastScroll = topPosition;

	if (topPosition < 5) {
		$('.header').addClass('is-visible');
	} else {
		$('.header').removeClass('is-visible');
	}
});
