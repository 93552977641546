/*
 * Polyfills
 */
import './polyfills/object-fit';

/*
 * Modules
 */
 import './modules/fullheight';
 import './modules/slider-fullheight-images';
 import './modules/get-quote';
 import './modules/header';
 import './modules/nav';
 import './modules/map';
 import './modules/gallery';
 import './modules/animations';
 import './modules/change-form-title';

