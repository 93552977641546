import { $win } from '../utils/globals';
import 'magnific-popup';

$('.gallery-items .gallery__item-popup').magnificPopup({
	type: 'image',
	mainClass: 'mfp-with-zoom',
	gallery:{
		enabled:true
	},
	fixedContentPos: true,
	fixedBgPos: true,
	closeBtnInside: false,
	callbacks: {
		buildControls: function() {
			this.contentContainer.append(this.arrowLeft.add(this.arrowRight));
		},
		open: function () {
			if (!$('.mfp-title').text().trim().length) {
				$('.mfp-title').parent().addClass('hidden');
			}
		}
	},
});
